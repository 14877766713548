// JS
import htmx from 'htmx.org/dist/htmx.esm.js'
import Sortable from 'sortablejs'
import {
  BusyWrapper,
  CountSelector,
  CopyToClipboard,
  DashboardMenu,
  DropdownMenu,
  FileUpload,
  MobileNav,
  PasswordToggle,
  PhoneNumberInput,
  ReservationSelector,
  SelectorBox,
  TopicCard,
  TopicSelector,
  UsernameInput
} from './components/index.js'

window.htmx = htmx

// Config object
const config = {
  debounceDelay: 350,
  waitForComponents: [],
}

const componentsMap = {
  'busy-wrapper': BusyWrapper,
  'count-selector': CountSelector,
  'copy-to-clipboard': CopyToClipboard,
  'dashboard-menu': DashboardMenu,
  'dropdown-menu': DropdownMenu,
  'file-upload': FileUpload,
  'mobile-nav': MobileNav,
  'password-toggle': PasswordToggle,
  'phone-number-input': PhoneNumberInput,
  'reservation-selector': ReservationSelector,
  'selector-box': SelectorBox,
  'topic-card': TopicCard,
  'topic-selector': TopicSelector,
  'username-input': UsernameInput,
}

// Helper function to check if element is a button
function isButton (element) {
  return element.tagName === 'BUTTON' ||
    element.classList.contains('button')
}

// Handle buttons with hx-confirm or hx-prompt attributes
// This will prevent the button from being set to aria-busy
// until the confirm or prompt is resolved
function handleConfirmPromptModalButton (element) {
  if (!isButton(element)) return
  if (!element.hasAttribute('hx-confirm') && !element.hasAttribute('hx-prompt') && !element.hasAttribute('data-modal-button')) return

  // Only handle buttons with confirm/prompt
  element.removeAttribute('aria-busy')
}

// Scroll to the first error on the page. This is used when the page is reloaded after a form submission
// via HTMX. If the body has a data-menu-offset attribute, it will be used to offset the scroll position.
// function scrollToFirstError () {
//   const firstError = document.querySelector('.form-group.error')
//   if (firstError) {
//     const offset = document.body.dataset.menuOffset ? parseInt(document.body.dataset.menuOffset || '0') : 0
//     const rect = firstError.getBoundingClientRect()
//     const scrollPosition = window.scrollY + rect.top - offset
//
//     window.scrollTo({
//       top: scrollPosition,
//       behavior: 'smooth'
//     })
//   }
// }
function scrollToFirstError() {
  const firstError = document.querySelector('.form-group.error')
  if (!firstError) return

  const offset = document.body.dataset.menuOffset ? parseInt(document.body.dataset.menuOffset || '0') : 0
  const dashboardMain = document.querySelector('.dashboard__main')

  if (dashboardMain) {
    const rect = firstError.getBoundingClientRect()
    const containerRect = dashboardMain.getBoundingClientRect()
    const scrollPosition = firstError.offsetTop - containerRect.top - offset

    dashboardMain.scrollTo({
      top: scrollPosition,
      behavior: 'smooth'
    })
  } else {
    const rect = firstError.getBoundingClientRect()
    const scrollPosition = window.scrollY + rect.top - offset

    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth'
    })
  }
}

// Handle flash message close button
window.closeFlash = (button) => {
  const flashDiv = button.closest('#FlashMessage')
  flashDiv.classList.add('translate-x-full')
  setTimeout(() => {
    flashDiv.remove()
  }, 300)
}

// Handle form submission with enter key
window.preventEnterSubmit = (event) => {
  if (event.submitter?.type !== 'submit') {
    event.preventDefault()
    return false
  }
}

// Show flash messages if they exist
const showFlashMessages = () => {
  const flash = document.querySelector('#FlashMessage')
  if (flash) {
    setTimeout(() => {
      flash.classList.remove('hide')
    }, 100)

    const autoClose = flash.dataset.autoClose
    if (autoClose) {
      setTimeout(() => {
        flash.classList.add('hide')
      }, parseInt(autoClose))
    }
  }
}

// Handle htmx abort event
// document.body.addEventListener('htmx:abort', function (evt) {
//   handleConfirmPromptModalButton(evt.detail.elt)
// })

// Handle htmx confirm event
document.body.addEventListener('htmx:confirm', function (evt) {
  const elt = evt.detail.elt

  if (!elt.hasAttribute('hx-confirm')) return

  evt.preventDefault()

  // If the elt is of type BUTTON
  if (elt.tagName === 'BUTTON') {
    elt.setAttribute('aria-busy', 'true')
  }

  if (confirm(elt.getAttribute('hx-confirm'))) {
    evt.detail.issueRequest(true)
    return
  }

  elt.setAttribute('aria-busy', 'false')
})

// Handle htmx beforeRequest event
document.body.addEventListener('htmx:beforeRequest', function (evt) {
  // Clear error container when starting new request
  const errorContainer = document.getElementById('ErrorContainer')
  if (errorContainer) {
    errorContainer.innerHTML = ''
  }

  const element = evt.detail.elt
  if (isButton(element)) {
    element.setAttribute('aria-busy', 'true')
  }
})

// Handle htmx beforeSwap event
document.body.addEventListener('htmx:beforeSwap', function (evt) {
  if (evt.detail.xhr.status === 400 || evt.detail.xhr.status === 404 || evt.detail.xhr.status === 422 || evt.detail.xhr.status === 500) {
    // if the response code is 404, 422, or 500, we want to swap the content
    evt.detail.shouldSwap = true
    // set isError to 'false' to avoid error logging in console
    evt.detail.isError = false
  }

  if (evt.detail.xhr.status === 500) {
    if (!evt.detail.xhr.getResponseHeader('HX-Retarget')) {
      const errorContainer = document.getElementById('ErrorContainer')
      if (errorContainer) {
        evt.detail.target = errorContainer
      }
    }
  }
})

// Handle htmx responseError event
document.body.addEventListener('htmx:responseError', (evt) => {
  handleConfirmPromptModalButton(evt.detail.elt)
})

// Handle htmx afterRequest event
document.body.addEventListener('htmx:afterRequest', function (evt) {
  handleConfirmPromptModalButton(evt.detail.elt)
})

// Handle htmx afterOnLoad event for error scrolling. If the server responds with a header
// 'HX-Trigger: scrollToError', scroll to the first error on the page.
document.body.addEventListener('scrollToError', function (evt) {
  scrollToFirstError()
})

// Handle htmx afterSwap event
document.body.addEventListener('htmx:afterSwap', function (evt) {
  showFlashMessages()
})

// Listen for afterSettle event to handle modal closing events
document.addEventListener('htmx:afterSettle', (e) => {
  const modal = document.getElementById('modal')
  if (!modal) return

  // Lock the body when the modal is open
  document.body.classList.add('modal-open')

  const handleEscape = (e) => {
    if (e.key === 'Escape') {
      closeModal(modal)
    }
  }

  const handleClick = (e) => {
    // If the target matche .modal-unerlay or [data-close-modal], close the modal
    // Or, if the target is a child of a [data-close-modal] element, close the modal
    if (e.target.matches('.modal-underlay') || e.target.matches('[data-close-modal]') || e.target.closest('[data-close-modal]')) {
      e.preventDefault()
      closeModal(modal)
    }
  }

  // Add event listeners
  modal.addEventListener('click', handleClick)
  document.body.addEventListener('keydown', handleEscape)

  function closeModal (modal) {
    // Remove event listeners
    modal.removeEventListener('click', handleClick)
    document.body.removeEventListener('keydown', handleEscape)

    // Unlock the body when the modal is closed
    document.body.classList.remove('modal-open')

    modal.classList.add('closing')
    modal.addEventListener('animationend', () => {
      modal.remove()
    }, { once: true })
  }
})

// Define all custom elements
const defineComponents = async (componentsMap = {}) => {
  const definePromises = []

  // Define and collect all custom elements
  for (const [name, elementClass] of Object.entries(componentsMap)) {
    if (!customElements.get(name)) {
      customElements.define(name, elementClass)
      const definePromise = customElements.whenDefined(name)
      definePromises.push(definePromise)
    }
  }

  // Wait for all custom elements to be defined
  await Promise.allSettled(definePromises)

  // Wait for any additional components to be defined
  if (config.waitForComponents.length > 0) {
    const waitForComponents = config.waitForComponents.map((componentName) => {
      return customElements.whenDefined(componentName)
    })

    await Promise.allSettled(waitForComponents)
  }
}

// Apply sortable to all elements with the .sortable class
const applySortable = async (element) => {
  const sortables = element.querySelectorAll('.sortable')
  for (let i = 0; i < sortables.length; i++) {
    const sortable = sortables[i]
    new Sortable(sortable, {
      animation: 150,
      ghostClass: 'sortable-ghost',

      // Make the htmx-indicator unsortable
      filter: '.htmx-indicator',

      onMove: function (evt) {
        return evt.related.className.indexOf('htmx-indicator') === -1
      },

      // Make sure sorting is re-enabled after the drag ends
      onEnd: function (evt) {
        this.option('disabled', false)
      }
    })

    // Re-enable sorting on the `htmx:afterSwap` event
    sortable.addEventListener('htmx:afterSwap', function () {
      this.sortable.option('disabled', false)
    })
  }
}

// Handle show more button for truncatable content
// const handleShowMore = () => {
//   const truncatableContents = document.querySelectorAll('.card__content.truncatable')
//
//   truncatableContents.forEach(content => {
//     // Wrap content in a wrapper div if not already wrapped
//     let wrapper = content.parentElement
//     if (!wrapper.classList.contains('card__content-wrapper')) {
//       wrapper = document.createElement('div')
//       wrapper.className = 'card__content-wrapper'
//       content.parentNode.insertBefore(wrapper, content)
//       wrapper.appendChild(content)
//     }
//
//     // Only add show more button if content is actually overflowing
//     if (content.scrollHeight > 150) {
//       const showMoreBtn = document.createElement('div')
//       showMoreBtn.className = 'show-more'
//       showMoreBtn.innerHTML = '<button>&#8853; Show more</button>'
//
//       showMoreBtn.querySelector('button').addEventListener('click', () => {
//         content.classList.toggle('expanded')
//         showMoreBtn.querySelector('button').innerHTML =
//           content.classList.contains('expanded') ? '&#8861; Show less' : '&#8853; Show more'
//       })
//
//       // Add button after the content but within the wrapper
//       wrapper.appendChild(showMoreBtn)
//     }
//   })
// }

const handleAccordion = () => {
  const details = document.querySelectorAll('.details-accordion');

  details.forEach(detail => {
    const content = detail.querySelector('div');
    const summary = detail.querySelector('summary');

    // Store content height for animations
    let contentHeight = 0;

    const updateContentHeight = () => {
      // Temporarily remove transitions and height constraints
      content.style.transition = 'none';
      content.style.height = 'auto';
      // Get the height
      contentHeight = content.offsetHeight;
      // Reset to closed state if not open
      if (!detail.open) {
        content.style.height = '0';
      }
      // Re-enable transitions
      requestAnimationFrame(() => {
        content.style.transition = '';
      });
    };

    // Initial height calculation
    if (content) {
      updateContentHeight();
      // Update height on window resize
      window.addEventListener('resize', updateContentHeight);
    }

    summary.addEventListener('click', (e) => {
      e.preventDefault();

      if (detail.open) {
        // Closing animation
        content.style.height = `${contentHeight}px`;
        requestAnimationFrame(() => {
          content.style.height = '0';
        });
        // Wait for animation before closing
        setTimeout(() => {
          detail.open = false;
        }, 300);
      } else {
        // Opening animation
        detail.open = true;
        updateContentHeight();
        requestAnimationFrame(() => {
          content.style.height = `${contentHeight}px`;
        });
        // Remove fixed height after animation
        setTimeout(() => {
          content.style.height = 'auto';
        }, 300);
      }
    });
  });
}

// On page load, define all components
document.addEventListener('DOMContentLoaded', async () => {
  await defineComponents(componentsMap)

  // Remove the preload class from the body to show the page
  document.body.classList.remove('preload')

  // Redefine all components on htmx load
  window.htmx.onLoad(async function (element) {
    await defineComponents(componentsMap)
    await applySortable(element)
  })

  // Look for any meta values with x-replace-url and replace the current URL with the new URL in the browser history
  const replaceUrlMeta = document.querySelector('meta[name="x-replace-url"]')
  if (replaceUrlMeta) {
    const newUrl = replaceUrlMeta.getAttribute('content')
    window.history.replaceState({}, '', newUrl)
  }

  // Handle flash messages
  // handleShowMore()
  handleAccordion()
  showFlashMessages()
})
